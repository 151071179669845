import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import apfSteel from "./assets/apfSteel.png";
import apfTires from "./assets/apfTires.png";
import canadianLiftLogo from "./assets/canadianLiftLogo.png";
import apfGroup from "./assets/apfGroup.png";
import apfFittings from "./assets/apfFittings.png";
import calChamber from "./assets/calChamber.png";
import { GeoAltFill, TelephoneFill, EnvelopeFill } from "react-bootstrap-icons";
import { Col, Container, Row } from "react-bootstrap";
import BackgroundOverlay from "./backgroundOverlay";
import LineDivider from "./lineDivider";

function App() {
  return (
    <div className={"pageContainer"}>
      <section className="bannerSection">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <img src={apfGroup} alt="Company Group" />
            </Col>
          </Row>
        </Container>
        <BackgroundOverlay />
      </section>
      <div className="companySection">
        <section className="apfSection">
          <Container>
            <Row>
              <Col xs={12} md={6} lg={6} className="apfSteel">
                <img src={apfSteel} alt="Apf Steel" />
                <BackgroundOverlay />
              </Col>
              <Col xs={12} md={6} lg={6}>
                <h2>APF STEEL</h2>
                <LineDivider />
                <p>
                  Founded in 2021 as part of the APF Group, APF Steel offers a
                  wide range of structural steel products, including temporary
                  fences, hot dipped galvanized steel, cold rolled steel, color
                  coated steel, OCTG welded steel tubes, and seamless tubes. As
                  one of Canada’s largest steel distributors, we are dedicated
                  to supplying quality steel products in a range of grades,
                  widths, and shapes to our clients. Our top priorities at APF
                  Steel are quality products, affordability, and commitment to
                  our clients
                </p>
                <ul>
                  <li>
                    <a href="https://apfSteel.com/">
                      PVC &amp; HDG Tubes &amp; Wires
                    </a>
                  </li>
                  <li>
                    <a href="https://apfSteel.com/">Structural Steel</a>
                  </li>
                  <li>
                    <a href="https://apfSteel.com/">Fencing</a>
                  </li>
                  <li>
                    <a href="https://apfSteel.com/">Tracer Wire</a>
                  </li>
                </ul>
                <a href="https://apfSteel.com/" className="primaryBtn">
                  Visit Website
                </a>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="altApfSection">
          <Container>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <h2>APF TIRES</h2>
                <LineDivider />
                <p>
                  Founded in 2010, with the leadership having 30 years of
                  experience in the industry, APF Group was formed under Garry
                  Ahluwalia’s leadership in 2010 with the foundation of Alberta
                  Pipe Fittings Limited to service the Oil and Gas industry. APF
                  diversified its product range in 2016 in the Auto-sector under
                  the umbrella of APF TIRES to grow in Tires, wheels,
                  windshields and forklifts distribution business. We are a
                  professionally managed company that is committed on building
                  relations and satisfying our customers needs. When it comes to
                  quality products at competitive prices, we have earned a
                  reputation as a consistent global supplier of innovation,
                  quality and value. This makes APF Group an ideal partner for
                  any customer looking for dependability and consistency when it
                  comes to their industrial needs
                </p>
                <ul>
                  <li>
                    <a href="https://apftires.ca/">Forklifts</a>
                  </li>
                  <li>
                    <a href="https://apftires.ca/">Windshields</a>
                  </li>
                  <li>
                    <a href="https://apftires.ca/">Tires & Wheels</a>
                  </li>
                </ul>
                <a href="https://apftires.ca/" className="primaryBtn">
                  Visit Website
                </a>
              </Col>
              <Col xs={12} md={6} lg={6} className="apfTires">
                <img src={apfTires} alt="Apf Tires" />
                <BackgroundOverlay />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="apfSection">
          <Container>
            <Row>
              <Col xs={12} md={6} lg={6} className="apfFittings">
                <img src={apfFittings} alt="Apf Fittings" />
                <BackgroundOverlay />
              </Col>
              <Col xs={12} md={6} lg={6}>
                <h2>AP FITTINGS</h2>
                <LineDivider />
                <p>
                  In the modern era, the flowline industry is vital worldwide.
                  Obtaining materials and equipment from reputable, ethical, and
                  quality-focused manufacturers is a critical step in assuring
                  industry success. At APF, we maintain a strong emphasis on
                  logistics while adhering to our client's strict delivery
                  schedules to ensure that project sites are always stocked with
                  the necessary equipment, no matter the scope.
                  <br />
                  <br />
                  We at APF are steadfast in our commitment to surpassing
                  customer expectations in terms of quality, cost, and delivery.
                </p>
                <ul>
                  <li>
                    <a href="https://apfittings.com/">CS Pipes</a>
                  </li>
                  <li>
                    <a href="https://apfittings.com/">Flanges & Fittings</a>
                  </li>
                  <li>
                    <a href="https://apfittings.com/">Hammer Unions</a>
                  </li>
                </ul>
                <a href="https://apfittings.com/" className="primaryBtn">
                  Visit Website
                </a>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="altApfSection">
          <Container>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <h2>CANADIAN LIFT</h2>
                <LineDivider />
                <p>
                  At Canadian Lift Trucks & Machinery, we offer a wide range of
                  forklifts, from compact models to heavy-duty options, along
                  with equipment like skid steers for warehouse and yard work.
                  We also offer a range of e-bikes, expanding our commitment to
                  innovative solutions for both industrial and personal needs.
                  Our goal is to make your work easier by delivering reliable
                  machinery that boosts productivity and handles the toughest
                  tasks. With a focus on quality and safety, we ensure you have
                  the right tools to get the job done.
                </p>
                <ul>
                  <li>
                    <a href="https://canadianlifttrucks.ca/">Forklift</a>
                  </li>
                  <li>
                    <a href="https://canadianlifttrucks.ca/">Skid Steer</a>
                  </li>
                  <li>
                    <a href="https://canadianlifttrucks.ca/">E-Bikes</a>
                  </li>
                </ul>
                <a href="https://canadianlifttrucks.ca/" className="primaryBtn">
                  Visit Website
                </a>
              </Col>
              <Col xs={12} md={6} lg={6} className="canadianLift">
                <img src={canadianLiftLogo} alt="Canadian Lift" />
                <BackgroundOverlay />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="altApfSection contactDetail">
          <Container>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <h2>CONTACT US</h2>
                <LineDivider />
                <ul>
                  <li>
                    <a href="https://maps.app.goo.gl/hwztkdiPVfUdFdx39">
                      <GeoAltFill size={20} />
                      Unit 2109, 6027-79th Ave SE, Calgary, AB T2C 5P1
                    </a>
                  </li>
                  <li>
                    <a href="https://maps.app.goo.gl/yHvUEfKFksLfSr3s5">
                      <GeoAltFill size={20} />
                      1004-14 Ave, Niksu, AB T9E 0G9
                    </a>
                  </li>
                  <li>
                    <a href="tel:403-263-9010">
                      <TelephoneFill size={20} />
                      403-263-9010 ext. 106
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@apfgroup.ca">
                      <EnvelopeFill size={20} />
                      info@apfgroup.ca
                    </a>
                  </li>
                </ul>
              </Col>
              <Col xs={12} md={6} lg={6} className="apfContact">
                <h3>Proud Member of</h3>
                <img src={calChamber} alt="Calgary Chamber" />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section className={"bottomFooter"}>
        <Container>
          <Row>
            <Col xs={12} md={6} lg={6}>
              <p>2024 © APF GROUPS.</p>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <p>
                Website{" "}
                <a href={"https://x360digital.com/website-design/"}>Design</a>{" "}
                by <a href={"https://x360digital.com/"}>x360 Digital Inc.</a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <div className="apfGroup contentSection" onClick={openApfGroup}>
        <img src={apfGroup} alt="Company Group" />
        <div className="overlay"></div>
      </div>
      <div className="apfSteel contentSection closed" onClick={openApfSteel}>
        <div className="logoContainer">
          <img src={apfSteel} alt="Company Group" />
          <a href="https://apfSteel.com/">apf</a>
        </div>
        <div className="overlay"></div>
        <div className="aboutApfSteel hideText">
          <ul>
            <li>PVC & HDG Tubes & Wires</li>
            <li>Structural Steel</li>
            <li>Fencing</li>
            <li>Tracer Wire</li>
          </ul>
          <a href="https://apfSteel.com/" className="btnLink">
            Visit Website
          </a>
        </div>
      </div>
      <div className="apfTires contentSection closed" onClick={openApfTires}>
        <div className="logoContainer">
          <img src={apfTires} alt="Company Group" />
          <a href="https://apftires.ca/">apf</a>
        </div>
        <div className="overlay"></div>
        <div className="aboutApfTires hideText">
          <ul>
            <li>Forklifts</li>
            <li>Windshields</li>
            <li>Tires & Wheels</li>
          </ul>
          <a href="https://apfTires.ca/" className="btnLink">
            Visit Website
          </a>
        </div>
      </div>
      <div
        className="apfFittings contentSection closed"
        onClick={openApfFittings}
      >
        <div className="logoContainer">
          <img src={apfFittings} alt="Company Group" />
          <a href="https://apfittings.com/">apf</a>
        </div>
        <div className="overlay"></div>
        <div className="aboutApfFittings hideText">
          <ul>
            <li>CS Pipes</li>
            <li>Flanges & Fittings</li>
            <li>Hammer Unions</li>
          </ul>
          <a href="https://apfittings.com/" className="btnLink">
            Visit Website
          </a>
        </div>
      </div>
      <div
        className="apfContact contentSection closed"
        onClick={openApfContact}
      >
        <h2>Contact us</h2>
        <div className="overlay"></div>
        <div className="aboutApfContact hideText" style={{ marginTop: "50px" }}>
          <ul>
            <li>
              <GeoAltFill />
              Unit 2109, 6027-79th Ave SE, Calgary, AB T2C 5P1
            </li>
            <li>
              <GeoAltFill />
              1004-14 Ave, Niksu, AB T9E 0G9
            </li>
            <li>
              <TelephoneFill />
              <a href="tel:403-263-9010">403-263-9010 ext. 106</a>
            </li>
            <li>
              <EnvelopeFill />
                <a href="mailto:info@apfgroup.ca">info@apfgroup.ca</a>
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
}

export default App;
